
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    /* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import Head from 'next/head';
import Router from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import { getStorage } from 'src/lib/storage';
import store from 'src/store';
import '@shopify/polaris-viz/build/esm/styles.css';
import '@shopify/polaris/build/esm/styles.css';
import { App } from 'src/components/App';
import { AppWrapper } from 'src/components/AppWrapper';
import LoggingErrorBoundary from 'src/lib/LoggingErrorBoundary';
import { trackPage } from 'src/lib/tracking';
import 'src/components/styles.css';
import useTranslation from 'next-translate/useTranslation';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { isClientSide } from 'src/lib/utils';
if (isClientSide()) {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: `${window.location.origin}/ingest`,
        person_profiles: 'identified_only',
        loaded: posthog => {
            if (process.env.NEXT_PUBLIC_POSTHOG_DEBUG === 'enabled')
                posthog.debug();
        },
    });
}
const Dashboard = ({ Component, pageProps }) => {
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isAppDisabled, setIsAppDisabled] = useState(false);
    const storageRef = useRef(null);
    const { t } = useTranslation('polaris');
    const translations = t('Polaris', { count: 1 }, { returnObjects: true });
    useEffect(() => {
        storageRef.current = getStorage();
        Router.events.on('routeChangeComplete', () => {
            setIsPageLoading(false);
            trackPage();
        });
        Router.events.on('routeChangeError', () => {
            setIsPageLoading(false);
        });
        Router.events.on('routeChangeStart', () => {
            setIsPageLoading(true);
        });
        // Shut down backend on subdomain change, ex: switch thru multi-store login
        const handlePageVisibilityChange = () => {
            const user = storageRef.current.get('user');
            const subdomain = user?.website?.subdomain;
            if (!subdomain || !window._po_subdomain)
                return;
            if (subdomain !== window._po_subdomain &&
                !/onboarding/.test(window.location.href)) {
                setIsAppDisabled(true);
                window._po_disabled = true;
            }
        };
        document.addEventListener('visibilitychange', handlePageVisibilityChange);
        window._po_build = process.env.BUILD_ID;
    }, []);
    return (<LoggingErrorBoundary>
      <>
        <Head>
          <meta name='viewport' content='width=device-width, initial-scale=1'/>
          <link rel='icon' href='/static/pushowl.jpeg'/>
          <meta name='shopify-api-key' content={process.env.NEXT_PUBLIC_SHOPIFY_CLIENT_ID}/>
          <meta name='shopify-disabled-features' content='auto-redirect'/>
          <script src='/mfe-style-specificity.js'/>
        </Head>

        <PostHogProvider client={posthog}>
          <Provider store={store}>
            <AppWrapper>
              <App Component={Component} pageProps={pageProps} translations={{ Polaris: translations }} isAppDisabled={isAppDisabled} isPageLoading={isPageLoading}/>
            </AppWrapper>
          </Provider>
        </PostHogProvider>
      </>
    </LoggingErrorBoundary>);
};
const __Next_Translate__Page__1950296e992__ = Dashboard;

    export default __appWithI18n(__Next_Translate__Page__1950296e992__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  