import { createModel } from '@rematch/core';
import Router from 'next/router';
import posthog from 'posthog-js';
import { loginAPI, refetchUserAPI, supportLoginAPI } from 'src/lib/api/auth';
import Cookie from 'src/lib/cookie';
import { logErrorToSentry } from 'src/lib/debug-utils';
import { gotoNext } from 'src/lib/goto-next';
import { getStorage } from 'src/lib/storage';
import {
  handlePostLogin,
  isLocalStorageAvailable,
  isSmallScreen,
} from 'src/lib/utils';
import store, { RESET_APP } from 'src/store';
import { RootModel } from '.';

type UserState = {
  isLoggedIn: boolean;
  isAuthenticating: boolean;
  segmentData: {
    subdomain: string;
    isSupportLogin: boolean;
    traits: {
      created_at: number;
      email: string;
      name: string;
      subdomain: string;
    };
  };
  error: AnyObject;
  user: AnyObject;
};

const canTriggerPermissionURL = () => {
  if (isSmallScreen()) {
    // We don't want to trigger permission URL in Shopify mobile app
    return false;
  }

  if (window.location.pathname !== '/') {
    // Trigger reload only for embedded auth page
    return false;
  }

  // Trigger permission URL during login only if local storage is available
  return isLocalStorageAvailable();
};

const defaultUser = {
  id: null,
  name: null,
  email: null,
  website: {
    subdomain: null,
    platform: 'shopify',
    company_logo: null,
    current_plan: {},
    website_plan: {},
    override: {},
    trial: {},
    notification_badge: null,
    getting_started: 'completed',
    created_at: new Date().toISOString(),
    stripe_customer_id: null,
    flags: {
      new_dashboard: 'enabled',
      new_bfs_dashboard: 'enabled',
      toggled_to_bfs_dashboard: 'false',
    },
  },
  token: null,
  accountFlags: {},
};

export const URLsWithoutAuth = [
  '/login',
  '/shopify-login',
  '/subscribe',
  '/checkout/stripe',
  '/support/login',
];
const canSkipAuth = () => URLsWithoutAuth.includes(window.location.pathname);

const storage = getStorage();
const user = createModel<RootModel>()({
  state: {
    isLoggedIn: false,
    host: null,
    isAuthenticating: true,
    segmentData: {
      subdomain: '',
      isSupportLogin: false,
      traits: {
        created_at: 0,
        email: '',
        name: '',
        subdomain: '',
      },
    },
    error: {},
    user: storage.get('user') || defaultUser,
  } as UserState,

  reducers: {
    setUserLoggedIn(state, isLoggedIn: boolean) {
      return {
        ...state,
        isLoggedIn,
      };
    },

    setSegmentData(state, segmentData: UserState['segmentData']) {
      return {
        ...state,
        segmentData,
      };
    },

    setUser(state, payload: AnyObject) {
      return {
        ...state,
        user: payload,
      };
    },
    setError(state, payload: AnyObject) {
      return {
        ...state,
        error: payload,
      };
    },

    setUserFlag(state, payload: AnyObject) {
      return {
        ...state,
        user: {
          ...state.user,
          website: {
            ...state.user.website,
            flags: {
              ...state.user.website.flags,
              ...payload,
            },
          },
        },
      };
    },

    setWebsitePreference(
      state,
      { key, value }: { key: string; value: string | number },
    ): UserState {
      return {
        ...state,
        user: {
          ...state.user,
          website: {
            ...state.user.website,
            [key]: value,
          },
        },
      };
    },

    setIsAuthenticating(state, isAuthenticating: boolean) {
      return {
        ...state,
        isAuthenticating,
      };
    },
  },
  effects: {
    async authTokenLogin() {
      const queryParams = new URLSearchParams(window.location.search);
      const nextURL = queryParams.get('next');
      const loginType = queryParams.get('login_type');
      const platform = queryParams.get('platform');

      if (platform === 'bigcommerce') {
        const storage = getStorage();
        storage.set('is_bigc', true);
      }

      const loginData = {
        authToken: queryParams.get('authToken'),
        subdomain: queryParams.get('subdomain'),
        platform,
      };

      const res = await loginAPI(loginData);
      const { user, isLoggedIn, error } = res;
      if (isLoggedIn) {
        gotoNext(nextURL);
        handlePostLogin({
          user: { ...user, skipAnalytics: loginType === 'support' },
        });
      }

      if (error) {
        this.setError(error);
        store.dispatch.saveToast.showError('Unable to login');
      }
    },

    async supportLogin(data: {
      subdomain: string;
      password: string;
      platform: string;
    }) {
      store.dispatch({ type: RESET_APP });

      try {
        const res = await supportLoginAPI(data);
        const { user, error } = res;

        if (res.isLoggedIn) {
          Router.push('/');
          handlePostLogin({ user: { ...user, skipAnalytics: true } });
          return { user, isLoggedIn: true };
        }

        return { user: null, isLoggedIn: false, error };
      } catch (error) {
        this.setError(error);
        return { user: null, error };
      }
    },

    async refetchUser() {
      if (canSkipAuth()) {
        this.setIsAuthenticating(false);
        return;
      }

      const storage = getStorage();
      const oldUser = storage.get('user');
      if (oldUser) {
        try {
          const { website, token: authToken } = oldUser;
          const { subdomain, platform } = website;

          const { user } = await refetchUserAPI({
            subdomain,
            authToken,
            platform,
          });

          handlePostLogin({
            user: { ...user, skipAnalytics: oldUser.skipAnalytics },
          });
        } catch (error) {
          this.setIsAuthenticating(false);
          store.dispatch.saveToast.showError('Error fetching user');
        }
      } else {
        this.logout();
      }
    },

    async shopifyLogin() {
      if (canSkipAuth()) {
        this.setIsAuthenticating(false);
        return;
      }

      // Using window.location.search instead of router.query
      // because router.query has both URL and query params which will break the auth
      const searchString = window.location.search;
      const query = Object.fromEntries(new URLSearchParams(searchString));
      const PERMISSION_URL_COUNT_STORAGE_KEY = 'permissionURLCount';

      try {
        const response = await fetch(
          `${
            process.env.NEXT_PUBLIC_API_ENDPOINT
          }/auth/shopify/shopify-auth/?${new URLSearchParams(
            searchString,
          ).toString()}`,
        );
        const data = await response.json();
        const { is_existing_user: isExistingUser, redirect_url } = data;

        // We open up the permission URL again to get merchants email
        if (canTriggerPermissionURL()) {
          try {
            const storedPermissionURLCount = localStorage.getItem(
              PERMISSION_URL_COUNT_STORAGE_KEY,
            );

            const permissionURLCount = storedPermissionURLCount
              ? parseInt(storedPermissionURLCount, 10)
              : 0;

            // Make sure the merchant is not stuck in loop opening redirect_url repeatedly
            if (permissionURLCount < 1 && redirect_url) {
              localStorage.setItem(
                PERMISSION_URL_COUNT_STORAGE_KEY,
                `${permissionURLCount + 1}`,
              );
              window.open(redirect_url, '_top');
              return;
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
          }
        }

        // If the user has already installed, skip installation
        if (isExistingUser) {
          const {
            authToken,
            subdomain,
            user: { account, website },
          } = data;

          if (canTriggerPermissionURL()) {
            localStorage.removeItem(PERMISSION_URL_COUNT_STORAGE_KEY);
          }

          if (!authToken && !subdomain) {
            throw new Error('Missing authToken or subdomain');
          }

          // TODO: This code can be removed after we remove next param for /onboarding in the backend API
          if (window.location.pathname === '/' && query.next) {
            const nextState = JSON.parse(window.atob(query.next));
            const { path } = nextState;

            if (path !== '/') {
              // We don't want to navigate to `/` if we are already there
              Router.push(`${path}${searchString}`);
            }
          }

          // TODO: Change the format of user object to standard format everwhere and strictly type it
          handlePostLogin({ user: { website, ...account } });
          return;
        }
      } catch (error) {
        store.dispatch.saveToast.showError('Error logging in');
        logErrorToSentry({ error });
      }
    },
    async logout() {
      const storage = getStorage();
      try {
        storage.clear();
        storage.clearAll();
        Cookie.clear('intercom-session-ntcl5suv');
        // because react router doesn't work here
        window.location.href = '/shopify-login';
        window._po_subdomain = undefined;
        posthog.reset();
        return { user: null, error: null };
      } catch (err) {
        this.setError(err);
        return { user: null, error: err };
      }
    },
  },
});

export default user;
