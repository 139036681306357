import { get, getAxios, getUser, post } from './axios';

export const fetchAllIntegrationsAPI = () => {
  const response = get('/dashboard/integrations/');
  return response;
};

export const saveIntegrationConfigAPI = ({ handle, config }) => {
  return post(`/dashboard/integrations/${handle}/`, config);
};

export const postRechargeAPI = (apiToken: string) => {
  const user = getUser();
  const axios = getAxios();

  return axios
    .post(
      `/app-integration/recharge/${user.website.subdomain}/integrate/v1/`,
      {
        store_api_token: apiToken,
      },
      {
        baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v3`,
      },
    )
    .then(() => {
      return { data: 'success', error: null };
    })
    .catch(error => {
      return {
        error,
        data: null,
      };
    });
};

export const postJudgeMeAPI = () => {
  const axios = getAxios();
  return axios
    .get(`/app-integration/judgeme/auth/`, {
      baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v3`,
    })
    .then(res => {
      return { data: res.data, error: null };
    })
    .catch(error => {
      return {
        error,
        data: null,
      };
    });
};

export const fetchIntegrationListAPI = () => {
  const user = getUser();
  const axios = getAxios();

  return axios
    .get(`/app-integration/${user.website.subdomain}/integrations/`, {
      baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v3`,
    })
    .then((res: any) => {
      return { data: res?.data?.data, error: null };
    })
    .catch(error => {
      return {
        error,
        data: null,
      };
    });
};

export const updateIntegrationListAPI = async (id, newStatus) => {
  const user = getUser();
  const axios = getAxios();

  try {
    const response = await axios.put(
      `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v3/app-integration/${user.website.subdomain}/integrations/`,
      {
        status: newStatus,
      },
      {
        params: { id },
      },
    );
    if (response) {
      return { data: 'success', error: null };
    }
    return { data: null, error: null };
  } catch (error) {
    return { data: null, error };
  }
};
